<template>
  <v-container>

    <h1>
      <span>Редактирование информации о договоре</span>
    </h1>

    <v-form ref="form" v-if="apiLoaded">
      <v-tabs v-model="currentStep">
        <v-tab>Информация</v-tab>
        <v-tab>Этапы</v-tab>
        <v-tab>Дополнительно</v-tab>

        <v-tab-item transition="fade-transition" reverse-transition="fade-transition">


          <section class="form-wrap">

            <v-row>
              <v-col cols="12"><h2>Общая информация</h2></v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                    label="Статус"
                    dense outlined
                    :items="contractStatusDict"
                    item-text="title"
                    item-value="code"
                    v-model="contract.contractStatus"
                    :rules="[
                    (v=>(v != null) || 'Поле не должно быть пустым')
                  ]"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Номер договора" dense outlined
                    v-model.trim="contract.contractNumber"
                    :rules="[
                    (v=>(!v || v.length<=255) ||'Максимальное число символов - 255')
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-row>
              <v-col cols="6">
                <date-field
                    label="Дата заключения"
                    :value="contract.startDate"
                    @input="contract.startDate=$event;$forceUpdate()"
                    min-date="2000-01-01" max-date="2099-12-31"></date-field>
              </v-col>
              <v-col cols="6">
                <date-field
                    label="Дата окончания действия"
                    :value="contract.endDate"
                    @input="contract.endDate=$event;$forceUpdate()"
                    min-date="2000-01-01" max-date="2099-12-31"></date-field>
              </v-col>
            </v-row>

            <v-row style="margin-bottom: 10px">
              <v-col cols="12">
                <v-checkbox
                    label="Заключен по результатам тендера"
                    dense outlined
                    v-model="hasTender"
                    @change="hasTenderChanged()"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row v-if="hasTender">
              <v-col cols="12">
                <register-select-field
                    field-title="Тендер"
                    dialog-title="Выбор тендера"
                    :reg-config="supTendersConfig"
                    page-size="10"
                    default-tab="0"
                    :text-function="item => item.theme || ''"
                    text-search-attribute="theme"
                    :value-object="contract.notification"
                    @select="onNotificationSelect($event)"
                    @clear="contract.notification = null; $forceUpdate();"
                >
                </register-select-field>
              </v-col>
            </v-row>


            <v-row v-if="hasTender">
              <v-col cols="12">
                <v-select
                    label="Закон" dense outlined clearable
                    :items="purchaseLawDict"
                    item-value="code" item-text="title"
                    v-model="contract.purchaseLaw"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Ссылка на zakupki.gov.ru" dense outlined v-if="contract.purchaseLaw"
                    v-model.trim="contract.url"
                    :rules="[
                    (v=>(!v || v.length<=2000) ||'Максимальное число символов - 2000')
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                    outlined label="Предмет договора"
                    v-model.trim="contract.theme"
                    :rules="[
                    (v=>(v != null && v.length > 0) || 'Поле не должно быть пустым'),
                    (v=>(v && v.length<=4000) ||'Максимальное число символов - 4000')
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Сумма договора" dense outlined
                    v-model.trim="contract.contractSum"
                    :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <register-select-field
                    field-title="Заказчик"
                    dialog-title="Выбор заказчика"
                    :reg-config="customerRegisterConfig"
                    page-size="10"
                    default-tab="0"
                    :text-function="item => item.fullName || ''"
                    text-search-attribute="fullName"
                    :value-object="contract.customer"
                    @select="contract.customer = $event; $forceUpdate();"
                    @clear="contract.customer = null; $forceUpdate();"
                    not-null="true"
                >
                </register-select-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="mb-6">
                <a class="addOrg" href="/counterpartyEdit/" target="_blank">Добавить новую организацию</a>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <register-select-field
                    field-title="Исполнитель"
                    dialog-title="Выбор исполнителя"
                    presetActiveTab="3"
                    :reg-config="customerRegisterConfig"
                    page-size="10"
                    default-tab="0"
                    :text-function="item => item.fullName || ''"
                    text-search-attribute="fullName"
                    :value-object="contract.supplier"
                    @select="contract.supplier = $event; $forceUpdate();"
                    @clear="contract.supplier = null; $forceUpdate();"
                    not-null="true"
                >
                </register-select-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Шифр договора"  outlined
                    :value="contract.cypher"
                    :disabled="true"
                    hint="Заполняется автоматически после сохранения на основе введённых данных Исполнителя и Заказчика"
                    persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12"><h2>Ответственные</h2></v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                    label="Руководитель проекта"
                    :items="offerExperts"
                    item-text="fullName"
                    item-value="id"
                    return-object
                    v-model="contract.offerExpert"
                    outlined dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                    label="Ответственный за ведение"
                    :items="formalExperts"
                    item-text="fullName"
                    item-value="id"
                    return-object
                    v-model="contract.formalExpert"
                    outlined dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12"><h2>Финансы</h2></v-col>
            </v-row>

            <v-row style="margin-bottom: 10px">
              <v-col cols="12">
                <v-checkbox
                    label="Есть аванс"
                    dense outlined
                    v-model="hasAdvance"
                    @change="hasAdvanceChanged()"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row v-if="hasAdvance">
              <v-col cols="12">
                <v-text-field
                    label="Сумма аванса (руб)"
                    dense outlined v-model.trim="contract.advanceSum"
                    :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="НДС (%)" dense outlined
                    v-model.trim="contract.ndsPercent"
                    :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 100, 2)||'Введите корректный процент')]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox label="Обеспечение исполнения контракта"
                            v-model="contract.contractGuaranteeNeeded"></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                    label="Cпособ обеспечения"
                    dense outlined
                    v-if="contract.contractGuaranteeNeeded"
                    v-model="contract.contractGuaranteeType"
                    :items="guaranteeTypeDict"
                    item-text="title" item-value="code"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Cумма обеспечения (руб)"
                    dense outlined
                    v-if="contract.contractGuaranteeNeeded"
                    v-model.trim="contract.contractGuaranteeSum"
                    :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Комиссия за обеспечение (руб)"
                    dense outlined
                    v-if="contract.contractGuaranteeNeeded"
                    v-model.trim="contract.contractGuaranteeCommission"
                    :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox label="Гарантийные обязательства" v-model="contract.warrantyGuaranteeNeeded"></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <date-field
                    label="Дата истечения гарантийных обязательств"
                    v-if="contract.warrantyGuaranteeNeeded"
                    :value="contract.warrantyGuaranteeEndDate"
                    @input="contract.warrantyGuaranteeEndDate=$event"
                    min-date="2000-01-01" max-date="2099-12-31"></date-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                    label="Cпособ обеспечения гарантийных обязательств"
                    dense outlined
                    v-if="contract.warrantyGuaranteeNeeded"
                    v-model="contract.warrantyGuaranteeType"
                    :items="guaranteeTypeDict"
                    item-text="title" item-value="code"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Cумма обеспечения гарантийных обязательств (руб)"
                    dense outlined
                    v-if="contract.warrantyGuaranteeNeeded"
                    v-model.trim="contract.warrantyGuaranteeSum"
                    :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Комиссия за обеспечение гарантийных обязательств (руб)"
                    dense outlined
                    v-if="contract.warrantyGuaranteeNeeded"
                    v-model.trim="contract.warrantyGuaranteeCommission"
                    :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox label="Бумажный оригинал договора находится в архиве организации" v-model="contract.originalInArchive"></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox label="Проблемный" v-model="contract.problematic"></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12"><h2>Примечания</h2></v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                    outlined label="Условия привлечения исполнителей или иные примечания"
                    v-model.trim="contract.suppliersRestrictions"
                    :rules="[
                    (v=>(!v || v.length<=6000) ||'Максимальное число символов - 6000')
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>


            <v-row>
              <v-col cols="12"><h2>Контактные лица</h2></v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="ФИО" dense outlined
                    v-model.trim="contract.contactFIO"
                    :rules="[
                    (v=>(!v || v.length<=100) ||'Максимальное число символов - 100')
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Должность" dense outlined
                    v-model.trim="contract.contactPosition"
                    :rules="[
                    (v=>(!v || v.length<=50) ||'Максимальное число символов - 50')
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Телефон" dense outlined
                    v-model.trim="contract.contactPhone"
                    :rules="[
                    (v=>(!v || v.length<=50) ||'Максимальное число символов - 50')
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Электронная почта" dense outlined
                    v-model.trim="contract.contactEmail"
                    :rules="[
                    (v=>(!v || v.length<=50) ||'Максимальное число символов - 50')
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12"><h2>Информация для сайта</h2></v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox label="Отображать на сайте" v-model="contract.forSite"></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                    outlined label="Описание проекта"
                    v-model.trim="contract.description"
                    :rules="[
                    (v=>(!v || v.length<=12000) ||'Максимальное число символов - 12000')
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>


            <v-row>
              <v-col cols="12">
                <v-select
                    label="Рынки"
                    :items="supMarketDict"
                    item-text="title"
                    item-value="code"
                    v-model="contract.markets"
                    multiple chips outlined dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                    label="Технологии"
                    :items="technologiesDict"
                    item-text="title"
                    item-value="code"
                    v-model="contract.technologies"
                    multiple chips outlined dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                    label="Участники"
                    :items="offerExperts"
                    item-text="fullName"
                    item-value="id"
                    return-object
                    v-model="contract.participants"
                    multiple chips outlined dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                    label="Регионы исследования"
                    :items="regionsDict"
                    item-text="title"
                    item-value="id"
                    v-model="contract.regions"
                    return-object
                    multiple chips outlined dense
                ></v-select>
              </v-col>
            </v-row>
          </section>

        </v-tab-item>

        <v-tab-item transition="fade-transition" reverse-transition="fade-transition">

          <section class="form-wrap">

            <div v-for="(stage, stageIndex) in contract.stages" :key="stageIndex+'_stage'">
              <v-row>
                <v-col cols="12"><h2>Этап {{ stageIndex + 1 }}</h2></v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                      outlined label="Название этапа"
                      v-model.trim="stage.name"
                      :rules="[
                        (v=>(v != null && v.length > 0) || 'Поле не должно быть пустым'),
                        (v=>(v && v.length<=4000) ||'Максимальное число символов - 4000')
                    ]"
                  ></v-textarea>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Сумма этапа" dense outlined
                      v-model.trim="stage.stageSum"
                      :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]"
                  ></v-text-field>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12">
                  <date-field
                      label="Дата окончания"
                      :value="stage.endDate"
                      @input="stage.endDate=$event"
                      min-date="2000-01-01" max-date="2099-12-31"
                  ></date-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-checkbox label="Этап оплачен" v-model="stage.paid"></v-checkbox>
                  <a href="#" class="add-link" @click.prevent="removeStage(stageIndex)"
                     v-if="contract.stages.length > 1">-
                    удалить этап</a>
                </v-col>
              </v-row>
              <br><br><br>
            </div>
            <v-row>
              <v-col cols="12"><a href="#" class="add-link" @click.prevent="addStage()">+ добавить этап</a></v-col>
            </v-row>
          </section>
        </v-tab-item>


        <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
          <section class="form-wrap">

            <v-row>
              <v-col cols="12"><h2>Связанные договоры</h2></v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <register-select-field
                    v-if="getContractType() === 'CONTRACT_WITH_SUPPLIER'"
                    field-title="Связанный договор с заказчиком"
                    dialog-title="Выбор связанного договора с заказчиком"
                    :reg-config="contractsRegConfig"
                    page-size="10"
                    default-tab="0"
                    :text-function="item => item.theme || ''"
                    text-search-attribute="theme"
                    :value-object="contract.parentContract"
                    @select="contract.parentContract = $event; $forceUpdate();"
                    @clear="contract.parentContract = null; $forceUpdate();"
                >
                </register-select-field>
                <span v-else-if="getContractType() === undefined">Укажите заказчика и исполнителя, чтобы указать связанные договора</span>
                <span v-else>Связанные договора указываются в договорах с подрядчиками</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12"><h2>Дополнительные соглашения</h2></v-col>
            </v-row>

            <div v-for="(agreement, agreementIndex) in contract.agreements" :key="agreementIndex+'_agreement'">

              <v-row>
                <v-col cols="12">
                  <v-text-field
                      outlined label="Номер"
                      v-model.trim="agreement.number"
                      :rules="[
                    (v=>(!v || v.length<=255) ||'Максимальное число символов - 255')
                  ]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <date-field
                      label="Дата"
                      :value="agreement.date"
                      @input="agreement.date=$event"
                      min-date="2000-01-01" max-date="2099-12-31"></date-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                      label="Описание" dense outlined
                      v-model.trim="agreement.description"
                      :rules="[
                    (v=>(!v || v.length<=4000) ||'Максимальное число символов - 4000')
                  ]"
                  ></v-textarea>
                  <a href="#" class="add-link" @click.prevent="removeAgreement(agreementIndex)">-
                    удалить Дополнительное соглашение</a>
                </v-col>
              </v-row>

              <br><br><br>
            </div>

            <v-row>
              <v-col cols="12"><a href="#" class="add-link" @click.prevent="addAgreement()">+ добавить Дополнительное
                соглашение</a></v-col>
            </v-row>

            <br><br>

          </section>
        </v-tab-item>
      </v-tabs>

      <div style="margin-top: 50px;">
        <v-btn outlined @click="cancel()" style="margin-right: 10px;">Отменить</v-btn>
        <v-btn @click="submit">Сохранить</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>

import {
  contractStatusDict,
  guaranteeTypeDict,
  purchaseLawDict, purchaseProceduresDict,
  regionsDict,
  supMarketDict,
  technologiesDict
} from "@/modules/NSI";
import {
  counterpartiesRegisterConfig,
  customerContractsRegisterConfig,
  notificationsRegisterConfig
} from "@/components/register/RegisterConfigs";
import RegisterSelectField from "@/components/register/RegisterSelectField";
import DateField from "@/components/elements/DateField";
import {loadDataToObject} from "@/modules/CommonUtils";
import {deleteNullFields, normalizeNumber} from "@/modules/CommonUtils";
import api from "@/modules/api";
import {generateEmployeesRegisterConfig} from "@/modules/SharedFunctions";


export default {
  name: 'ContractEditComponent',
  components: {DateField, RegisterSelectField},
  data() {
    return {
      currentStep: 0,
      contract: {stages: [{}]},
      hasAdvance: false,
      hasTender: false,
      contractStatusDict: contractStatusDict,
      customerRegisterConfig: counterpartiesRegisterConfig,
      purchaseLawDict: purchaseLawDict,
      guaranteeTypeDict: guaranteeTypeDict,
      supMarketDict: supMarketDict,
      technologiesDict: technologiesDict,
      regionsDict: regionsDict,
      purchaseProceduresDict: purchaseProceduresDict,
      formalExperts: undefined,
      offerExperts: undefined,
      supTendersConfig: {tabs: [{title: 'Тендеры в СУП', config: notificationsRegisterConfig}]},
      contractsRegConfig: {
        tabs: [
          {
            title: 'C заказчиками',
            config: customerContractsRegisterConfig
          }
        ]
      },
      apiLoaded: false,
      apiError: false
    }
  },
  methods: {
    loadData: loadDataToObject,
    normalizeNumber: normalizeNumber,
    async submit() {
      // alert(this.errors)
      // // eslint-disable-next-line no-constant-condition
      // if(true)return

      if (!this.$refs.form.validate()) {
        alert('Не пройдена валидация формы. Проверьте все вкладки формы на наличие ошибок (неправильно заполненные поля выделены красным цветом)')
        return
      }
      if(!this.contract.customer.isOurOrg && !this.contract.supplier.isOurOrg) {
        alert('Заказчик или поставщик должен быть нашей организацией')
        return
      }


      //Проверка этапов
      const stagesSum = this.contract.stages.reduce((previousValue, currentElement)=>{return previousValue + +currentElement.stageSum}, 0)
      if(this.contract.contractSum < stagesSum ){
        alert('Сумма стоимостей этапов превышает общую сумму договора')
        return
      }

      let prevStageEndDate
      for(let stage of this.contract.stages){
        if(stage.endDate) {
          if (this.contract.startDate && this.contract.startDate > stage.endDate) {
            alert('Дата окончания одного из этапов ранее даты начала договора')
            return
          }
          if (this.contract.endDate && this.contract.endDate < stage.endDate) {
            alert('Дата окончания одного из этапов превышает дату окончания договора')
            return
          }
          if(prevStageEndDate && prevStageEndDate > stage.endDate){
            alert('Дата окончания одного из этапов превышает дату окончания предыдущего этапа')
            return
          }
          prevStageEndDate = stage.endDate
        }
      }

      if (!this.contract.contractGuaranteeNeeded) {
        delete this.contract.contractGuaranteeType
        delete this.contract.contractGuaranteeSum
        delete this.contract.contractGuaranteeCommission
      }
      if (!this.contract.warrantyGuaranteeNeeded) {
        delete this.contract.warrantyGuaranteeEndDate
        delete this.contract.warrantyGuaranteeType
        delete this.contract.warrantyGuaranteeSum
        delete this.contract.warrantyGuaranteeCommission
      }
      if (this.getContractType() !== 'CONTRACT_WITH_SUPPLIER') {
        delete this.contract.parentContract
      }
      if (!this.contract.purchaseLaw) delete this.contract.url

      this.contract.ndsPercent = normalizeNumber(this.contract.ndsPercent, 0, 100, 2);
      this.contract.contractSum = normalizeNumber(this.contract.contractSum, 0, 1e13, 2);
      this.contract.advanceSum = normalizeNumber(this.contract.advanceSum, 0, 1e13, 2);
      this.contract.contractGuaranteeSum = normalizeNumber(this.contract.contractGuaranteeSum, 0, 1e13, 2);
      this.contract.contractGuaranteeCommission = normalizeNumber(this.contract.contractGuaranteeCommission, 0, 1e13, 2);
      this.contract.warrantyGuaranteeSum = normalizeNumber(this.contract.warrantyGuaranteeSum, 0, 1e13, 2);
      this.contract.warrantyGuaranteeCommission = normalizeNumber(this.contract.warrantyGuaranteeCommission, 0, 1e13, 2);
      for (let i = 0; i <  this.contract.stages.length; i++) {
        let stage = this.contract.stages[i]
        stage.stageNumber = i + 1
        stage.stageSum = normalizeNumber(stage.stageSum, 0, 1e13, 2);
      }

      this.contract = deleteNullFields(this.contract, true);
      console.log('contract', this.contract)

      let req;
      if (this.contractId) req = await api.putJson('/supmain/contracts/', this.contract)
      else req = await api.postJson('/supmain/contracts/', this.contract)
      console.log(req)
      if (req.ok) {
        this.$router.push({path: '/contract/' + req.payload.id + '/info'})
      } else {
        alert('Что-то пошло не так')
      }
    },

    addStage() {
      if (!this.contract.stages) this.contract.stages = []
      this.contract.stages.push({})
      this.$forceUpdate()
    },

    removeStage(index) {
      this.contract.stages.splice(index, 1)
      this.$forceUpdate()
    },

    addAgreement() {
      if (!this.contract.agreements) this.contract.agreements = []
      this.contract.agreements.push({})
      this.$forceUpdate()
    },

    removeAgreement(index) {
      this.contract.agreements.splice(index, 1)
      this.$forceUpdate()
    },

    getContractType() {
      if (!this.contract.customer || !this.contract.supplier) return undefined
      if (!this.contract.customer.isOurOrg && this.contract.supplier.isOurOrg) return 'CONTRACT_WITH_CUSTOMER'
      else if (this.contract.customer.isOurOrg && !this.contract.supplier.isOurOrg) return 'CONTRACT_WITH_SUPPLIER'
      else if (!this.contract.customer.isOurOrg && !this.contract.supplier.isOurOrg) return 'EXTERNAL_CONTRACT'
      else return 'INTERNAL_CONTRACT'
    },

    onNotificationSelect(notification) {
      this.contract.notification = notification
      if (notification) {
        this.contract.customer = notification.customer
        this.contract.theme = notification.theme
        this.contract.url = notification.url
        if (notification.procedure) {
          this.contract.purchaseLaw = purchaseProceduresDict.find(e => e.code === notification.procedure).law
        }
      }
      this.$forceUpdate()
    },

    cancel() {
      if (this.contractId != null) {
        this.$router.push({path: '/contract/' + this.contractId + '/info'})
      } else {
        this.$router.push({path: '/contracts/'})
      }
    },

    hasAdvanceChanged(){
      if(!this.hasAdvance){
        this.contract.advanceSum = 0
      }
    },

    hasTenderChanged(){
      if(!this.hasTender){
        this.contract.notification = null
        this.contract.purchaseLaw = null
      }
    },
  },
  async beforeMount() {
    if(this.$route.hash === '#stages') {
      this.currentStep = 1
    } else if (this.$route.hash === '#additional') {
      this.currentStep = 2
    }

    await Promise.all([
      this.loadData('/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true', 'offerExperts', true),
      this.loadData('/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleFormal:true', 'formalExperts', true)
    ])
    const getFullName = function (items) {
      return items.map(e => {
        e.fullName = [e.surname, e.name, e.middleName].join(' ')
        if (e.fullName === '  ') e.fullName = 'Без имени'
        return e
      })
    }
    this.offerExperts = getFullName(this.offerExperts)
    this.formalExperts = getFullName(this.formalExperts)

    this.contractId = this.$route.params.id
    if(this.contractId) {
      await this.loadData('/supmain/contracts/' + this.contractId, 'contract', false)
      this.contract.stages.sort((a, b)=>{return a.stageNumber - b.stageNumber})
      this.contract.agreements.sort((a, b)=>{return new Date(a.date) - new Date(b.date)})
      this.hasAdvance = this.contract.advanceSum > 0
      this.hasTender = this.contract.notification != null || this.contract.purchaseLaw != null
    }

    const employeesRegisterConfig = await generateEmployeesRegisterConfig()
    this.contractsRegConfig.tabs[0].config.filters
        .find(e=>{return e.path === 'offerExpert.id'})
        .regConfig = employeesRegisterConfig
    this.contractsRegConfig.tabs[0].config.filters
        .find(e=>{return e.path === 'formalExpert.id'})
        .regConfig = employeesRegisterConfig

    this.apiLoaded = true
  }
}
</script>

<style lang="scss">
 .form-wrap .v-messages__message {
    margin-bottom: 20px;
  }
  .addOrg {
    position: absoluite;
    left: 730px;
    margin-top: 10px;
  }
</style>
